<template>
  <div>
    <el-breadcrumb class="Bdh" separator-class="el-icon-arrow-right">
      <el-breadcrumb-item>诊单管理</el-breadcrumb-item>
      <el-breadcrumb-item>分配寻医表</el-breadcrumb-item>
    </el-breadcrumb>

    <div class="add">
      <el-date-picker
        v-model="date"
        type="daterange"
        align="right"
        unlink-panels
        range-separator="至"
        start-placeholder="开始日期"
        end-placeholder="结束日期"
        format="yyyy-MM-dd"
        value-format="yyyy-MM-dd"
        clearable
        style="width: 280px; margin-right: 10px"
        size="small"
      >
      </el-date-picker>
      <el-input
        placeholder="请输入客户编号"
        clearable
        prefix-icon="el-icon-search"
        v-model="custid"
        style="width: 200px; margin-right: 10px"
        @clear="getall(1, '')"
        size="small"
      >
      </el-input>
      <el-input
        placeholder="请输入寻医表编号"
        clearable
        prefix-icon="el-icon-search"
        v-model="searchfiel"
        style="width: 200px; margin-right: 10px"
        @clear="getall(1, '')"
        size="small"
      >
      </el-input>
      <el-button type="primary" @click="query" size="small">查询</el-button>
    </div>

    <el-table :data="list" border style="width: 100%">
      <el-table-column prop="KID" label="寻医单编号"></el-table-column>
      <el-table-column prop="Dis_Name" label="寻医单名"></el-table-column>
      <el-table-column prop="Cust_ID" label="患者编号"></el-table-column>
      <el-table-column prop="State" label="状态">
        <template slot-scope="scope">
          <span>{{ transformstate(scope.row.State) }}</span>
        </template>
      </el-table-column>
      <el-table-column>
        <template slot-scope="scope">
          <div class="opt">
            <el-button
              size="mini"
              type="warning"
              icon="el-icon-view"
              @click="lookup(scope.row)"
              >查看</el-button
            >
            <el-button
              v-if="scope.row.State == 6"
              size="mini"
              type="primary"
              icon="el-icon-user-solid"
              @click="assigDoc(scope.row)"
              >指定医生</el-button
            >
          </div>
        </template>
      </el-table-column>
    </el-table>
    <p class="paging">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="current"
        :page-sizes="pageSizes"
        :page-size="PageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="totalCount"
        :hide-on-single-page="value"
      ></el-pagination>
    </p>
    <el-dialog
      title="寻医表"
      :modal="false"
      :visible.sync="EditXY"
      width="566px"
    >
      <xypreview
        :kid="EditXYMsg.kid"
        :custid="EditXYMsg.custid"
        v-if="EditXY"
        :EditZD="EditXY"
        :noedit="EditXYMsg.noedit"
      ></xypreview>
    </el-dialog>
    <talkassigDoc
      :assigdoctor.sync="assigdoctor"
      :assigdoctoritem="assigdoctoritem"
    ></talkassigDoc>
  </div>
</template>
<script>
import xypreview from "../components/xypreview.vue";
import talkassigDoc from "../components/talkassigDoc2.vue";
export default {
  data() {
    return {
      list: [],
      current: 1, //默认显示第几页
      totalCount: 0, // 总条数，根据接口获取数据长度
      pageSizes: [20], // 个数选择器（可修改）
      PageSize: 20, // 默认每页显示的条数（可修改）
      value: false,
      EditXY: false,
      EditXYMsg: {
        kid: "",
        custid: "",
        noedit: "",
      },
      searchfiel: "",
      date: [],
      assigdoctor: false,
      assigdoctoritem: "",
      custid: "",
    };
  },
  watch: {
    date(newDate, oldDate) {
      if (newDate == null) {
        this.date = [];
      }
      if (newDate.length > 0) {
        this.searchfiel = "";
      }
    },
  },
  components: {
    xypreview,
    talkassigDoc,
  },
  mounted() {
    this.getall(1, "");
  },
  methods: {
    handleSizeChange() {},
    handleCurrentChange(currentPage) {
      this.getall(currentPage, "");
    },
    getall(page, date) {
      var str = "",
        str1 = "";
      if (date.length > 0) {
        str = "&datetime1=" + date[0] + "&datetime2=" + date[1];
      }
      if (this.custid != "") {
        str1 = "&cust_id=" + this.custid;
      }
      this.axios
        .get(
          "/field/get_z_newseadoc_mains?state=6&page=" +
            page +
            "&size=" +
            this.PageSize +
            "&doc_id=null" +
            str +
            str1
        )
        .then((res) => {
          if (res.data.code == 0) {
            this.list = res.data.result;
            this.totalCount = res.data.count;
          } else {
            this.list = [];
            this.totalCount = 0;
          }
        });
    },
    query() {
      if (this.searchfiel == "") {
        // if (this.date.length > 0) {
          this.getall(1, this.date);
        // } else {
        //   this.$message({
        //     message: "请选择日期",
        //     type: "error",
        //   });
        // }
      } else {
        this.date = [];
        this.axios
          .get("/field/get_z_newseadoc_main_by_kid?kid=" + this.searchfiel)
          .then((res) => {
            if (res.data.code == 0) {
              this.list = [];
              this.list.push(res.data.result);
            } else {
              console.log(res);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    transformstate(val) {
      var str = "";
      var dic = {
        0: "未填写",
        1: "待挂号",
        2: "已抓取",
        // 3: "打回",
        6: "审核",
        9: "删除",
      };
      for (var key in dic) {
        if (key == val) {
          str = dic[key];
        }
      }
      return str;
    },
    lookup(row) {
      this.EditXY = true;
      this.EditXYMsg.kid = row.KID;
      this.EditXYMsg.custid = row.Cust_ID;
      this.EditXYMsg.noedit = false;
    },
    assigDoc(row) {
      this.assigdoctor = true;
      this.assigdoctoritem = row;
    },
  },
};
</script>
<style>
.Bdh {
  height: 50px;
  width: 100%;
  line-height: 40px;
}

.add {
  width: 100%;
  height: 50px;
  text-align: left;
}
.opt a {
  cursor: pointer;
  color: green;
  margin-right: 20px;
  display: inline-block;
}
</style>
