<template>
  <div class="bg">
    <div class="phonemain">
      <div class="table-main">
        <div class="list-content" v-if="list.length > 0">
          <div class="bianhao">
            <p>
              编号：<span>{{ kid }}</span>
            </p>
          </div>
          <div class="bianhao">
            <p
              style="
                border-bottom: 1px dashed #d0d0d0;
                padding-bottom: 2px;
                padding-left: 14px;
              "
            >
              ID：<span>{{ usercustID }}</span
              ><span style="float: right">{{ filetime(createtime) }}</span>
            </p>
          </div>
          <div v-for="(item, index) in list" :key="index">
            <div v-if="item.KFiel_Type == 0">
              <h3>{{ item.KFiel_ShowCaption }}</h3>
            </div>
            <div v-if="item.KFiel_Type == 1">
              <van-field
                v-if="
                  item.KFiel_ShowCaption == '姓名' ||
                  item.KFiel_ShowCaption == '性别'
                "
                border
                v-model="item.Values"
                :label="item.KFiel_ShowCaption + '：'"
                :placeholder="'请输入' + item.KFiel_ShowCaption"
                :rules="[{ required: true, message: '请填写用户名' }]"
                class="cwidh"
                :disabled="!noedit"
              >
              </van-field>
              <van-field
                v-else
                border
                v-model="item.Values"
                :label="item.KFiel_ShowCaption + '：'"
                :placeholder="'请输入' + item.KFiel_ShowCaption"
                :rules="[{ required: true, message: '请填写用户名' }]"
                :disabled="!noedit"
              >
              </van-field>
            </div>
            <div v-if="item.KFiel_Type == 2">
              <van-field
                border
                v-model="item.Values"
                :label="item.KFiel_ShowCaption + '：'"
                type="textarea"
                autosize
                :placeholder="'请输入' + item.KFiel_ShowCaption"
                :rules="[{ required: true, message: '请填写用户名' }]"
                :disabled="!noedit"
              >
              </van-field>
            </div>
            <div v-if="item.KFiel_Type == 5 || item.KFiel_Type == 7">
              <van-field :name="item.KFiel_ShowCaption">
                <template #input>
                  <div style="width: 100%; box-sizing: border-box">
                    <div class="doc-title">
                      {{ item.KFiel_ShowCaption + "：" }}
                    </div>
                    <div class="doc-list">
                      <van-radio-group
                        v-model="item.Values"
                        :disabled="!noedit"
                      >
                        <van-radio
                          v-for="(it, idx) in item.checklist"
                          @click="check2(it)"
                          :key="idx"
                          :name="it.KFValues"
                          style="margin-bottom: 10px"
                        >
                          {{ it.KFValues }}
                        </van-radio>
                      </van-radio-group>
                    </div>
                  </div>
                </template>
              </van-field>
            </div>
            <div v-if="item.KFiel_Type == 6">
              <van-field :name="item.KFiel_ShowCaption">
                <template #input>
                  <div style="width: 100%; box-sizing: border-box">
                    <div class="doc-title">
                      {{ item.KFiel_ShowCaption + "：" }}
                    </div>
                    <div class="doc-list">
                      <van-checkbox-group
                        v-model="item.list"
                        :disabled="!noedit"
                      >
                        <van-checkbox
                          v-for="(it, idx) in item.checklist"
                          @click="check(item.list, item)"
                          :key="idx"
                          shape="square"
                          :name="it.KFValues"
                          style="margin-bottom: 10px"
                        >
                          {{ it.KFValues }}</van-checkbox
                        >
                      </van-checkbox-group>
                    </div>
                  </div>
                </template>
              </van-field>
            </div>
            <div v-if="item.KFiel_Type == 8">
              <h5>{{ item.KFiel_ShowCaption }}</h5>
            </div>
            <div v-if="item.KFiel_Type == 9">
              <van-field
                v-if="
                  item.KFiel_ShowCaption == '年龄' ||
                  item.KFiel_ShowCaption == '身高(CM)' ||
                  item.KFiel_ShowCaption == '体重(KG)'
                "
                border
                type="digit"
                v-model="item.Values"
                :label="item.KFiel_ShowCaption + '：'"
                :placeholder="'请输入' + item.KFiel_ShowCaption"
                class="cwidh"
                :rules="[{ required: true, message: '请填写用户名' }]"
                :disabled="!noedit"
              >
              </van-field>
              <van-field
                v-else
                border
                type="digit"
                v-model="item.Values"
                :label="item.KFiel_ShowCaption + '：'"
                :placeholder="'请输入' + item.KFiel_ShowCaption"
                :rules="[{ required: true, message: '请填写用户名' }]"
                :disabled="!noedit"
              >
              </van-field>
            </div>
          </div>
        </div>
        <div v-if="list2.length > 0">
          <div class="box box2">
            <div style="padding-top: 10px"></div>
            <div class="list-label" style="margin: 10px">问诊详情</div>
            <div class="doc-item flex-row">
              <div class="doc-key">
                <p>问诊编号</p>
              </div>
              <div class="doc-value">
                <p>{{ id }}</p>
              </div>
            </div>
            <div
              class="doc-item flex-row"
              v-for="(item, index) in list2"
              :key="index"
            >
              <div class="doc-key">
                <p>{{ item.KFiel_ShowCaption }}：</p>
              </div>
              <div class="doc-value">
                <p>{{ item.KFValues }}</p>
              </div>
            </div>
            <div class="half-slot"></div>
          </div>
        </div>
        <div style="margin: 16px" v-if="noedit">
          <van-button
            round
            block
            type="info"
            v-if="state == 0 || state == 1"
            native-type="submit"
            @click="save('save')"
          >
            保存
          </van-button>
        </div>
        <div style="margin: 16px" v-if="noedit">
          <van-button
            v-if="state == 0 || state == 1"
            round
            block
            type="info"
            native-type="submit"
            @click="save('sub')"
          >
            提交
          </van-button>
        </div>
        <div class="slot"></div>
      </div>
    </div>
    <van-button
      v-if="isshenhe"
      round
      block
      type="info"
      native-type="submit"
      @click="shenhexy"
    >
      审核
    </van-button>
    <van-button
      v-if="ishelp"
      round
      block
      type="info"
      native-type="submit"
      @click="sendtouser"
    >
      发送给患者
    </van-button>
  </div>
</template>
<script>
import Vue from "vue";
import Vant from "vant";
import "vant/lib/index.css";
import "../assets/biaostyle.css";
Vue.use(Vant);
export default {
  props: ["kid", "custid", "EditXY", "noedit", "isshenhe", "ishelp"],
  data() {
    return {
      success: false,
      id: "",
      showBtn: false,
      show: false,
      list: [],
      select_items: [],
      KFiel_ID: "",
      desc: "",
      photo: [],
      list2: [],
      state: 0,
      YSTZFA: "",
      YDFA: "",
      BZ: "",
      usercustID: "",
      createtime: "",
      illlibid: "",
      servicerid: sessionStorage.getItem("servicer_id"),
      kidmsg: "",
      issave:false,
    };
  },
  watch: {
    kid: function (newValue, oldValue) {
      this.id = newValue;
    },
  },
  mounted() {
    this.axios
      .get("/field/get_view_newgid?kid=" + this.kid)
      .then((res) => {
        if (res.data.code == "0") {
          this.usercustID = res.data.dismain.Cust_ID;
          this.illlibid = res.data.dismain.ill_lib_id;
          this.dismain = res.data.dismain;
          this.createtime = res.data.dismain.Creat_DT;
          if (res.data.dismain.State == 9) {
            this.state = 9;
            this.success = true;
            // return;
          }
          var photo = res.data.photo == null ? [] : res.data.photo;
          var fiels = res.data.fiels == null ? [] : res.data.fiels;
          this.photo = photo.map((item) => item.FileName);
          this.state = res.data.dismain.State;
          for (let i = 0; i < res.data.result.length; i++) {
            var item = res.data.result[i];
            item.Values = "";
            item.Values = "";
            if (item.checklist) {
              item.list = [];
            }
            for (let index = 0; index < fiels.length; index++) {
              var element = fiels[index];
              if (element.KFiel_ID == item.KFiel_ID) {
                item.Values = element.KF_Values;
                if (item.checklist) {
                  item.list = element.KF_Values.split(";");
                }
                continue;
              }
            }
            this.list.push(item);
          }
          this.list = this.list.sort((a, b) => {
            return a.KOrder - b.KOrder;
          });
          this.$nextTick(() => {
            this.showBtn = true;
            this.success = true;
          });
        } else {
        }
      })
      .catch((res) => {
        console.log(res);
      });
  },
  methods: {
    filetime(val) {
      return val.substr(2, 9);
    },
    removeImg(index) {
      this.photo.splice(index, 1);
    },
    check(list, item) {
      item.Values = "";
      item.Values = list.join(";");
    },
    check2(item) {
      item.Values = item.KFValues;
    },
    photoFilter(src) {
      var url = "http://192.168.11.253:8080/public/" + src;
      return url;
    },
    upload(content) {
      let formData = new FormData();
      formData.append("file", content.file); // 'file[]' 代表数组 其中`file`是可变的
      this.axios
        .post(
          "http://192.168.11.253:8080/qiniu/examples/upload_simple_file3.php",
          formData
        )
        .then((rs) => {
          if (rs.data.code == 0) {
            this.photo.push(rs.data.file);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    handleAvatarSuccess(res, file) {
      this.imageUrl = URL.createObjectURL(file.raw);
    },
    beforeAvatarUpload(file) {
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        this.$message.error("上传图片大小不能超过 20MB!");
      }
      return isLt2M;
    },
    //保存或提交
    save(type) {
      var list = this.list.map((item) => {
        var obj = new Object();
        obj.KFiel_ID = item.KFiel_ID;
        obj.KFiel_Type = item.KFiel_Type;
        obj.KFiel_ShowCaption = item.KFiel_ShowCaption;
        obj.KDis_ID = item.KDis_ID;
        obj.Values = item.Values;
        obj.KOrder = item.KOrder;
        return obj;
      });
      var form = {};
      var str = "";
      if (type == "save") {
        //保存
        form = {
          id: this.kid,
          list: JSON.stringify(list),
          photo: JSON.stringify(this.photo),
          change_state: 0,
        };
        str = "保存";
      } else {
        //提交
        form = {
          id: this.kid,
          list: JSON.stringify(list),
          photo: JSON.stringify(this.photo),
        };
        str = "提交";
      }
      //.filter('')item => item.Values != null && item.Values != ''
      this.$confirm("此操作将" + str + "表单, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.axios
            .post("/field/insert_z_newseadoc_record", this.qs.stringify(form))
            .then((res) => {
              if (res.data.code == "0") {
                this.$message({
                  message: "已" + str,
                  type: "success",
                });
                this.issave = true;
              } else {
                this.$message({
                  message: str + "失败," + res.data.msg,
                  type: "error",
                });
              }
            })
            .catch((res) => {
              console.log(res);
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "取消删除",
          });
        });
    },
    shenhexy() {
      var ill = {
        cust_id: this.usercustID,
        ill_lib_id: this.illlibid,
      };
      console.log(ill);
      this.axios
        .post(
          "/field/update_z_newseadoc_main_state",
          this.qs.stringify({ state: "6", kid: this.kid, uid: this.servicerid })
        )
        .then((res) => {
          if (res.data.code == 0) {
            this.$message({
              message: "审核成功",
              type: "success",
            });
            // this.State = this.changestate;
            // sessionStorage.setItem("MedicalType", this.changestate);
            // this.reload();
          } else {
            console.log(res);
          }
        })
        .catch((err) => {
          console.log(err);
        });
      this.axios
        .post("/gu/insert_gu_ill_lib_cust", this.qs.stringify(ill))
        .then((res) => {
          if (res.data.code == 0) {
            this.$message({
              message: "疾病绑定成功",
              type: "success",
            });
          } else {
            this.$message({
              message: res.data.msg,
              type: "error",
            });
          }
        });
    },
    //客服填写完可以选择发送给患者
    sendtouser() {
      if(this.issave){

        this.$emit("choicexy", this.dismain);
      }else{
        this.$message({
              message: '寻医表还没有保存，请先点击保存按钮',
              type: "error",
            });
      }
    },
  },
};
</script>
