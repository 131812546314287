<template>
  <div>
    <el-dialog title="请选择医生" :visible.sync="show" width="40%" @close="exit()">
      <!-- <p>请给{{assigdoctoritem.KID}}选择指定医生</p> -->
      <p>患者 {{assigdoctoritem.Cust_ID}} 的 {{assigdoctoritem.Dis_Name}} 寻医表 {{assigdoctoritem.KID}}</p>
      <p>请选择指定医生</p>
      <el-radio-group v-model="binddoccheckbox" style="text-align:left;">
        <el-radio
          style="height: 50px; line-height: 50px;width:100%;margin-right:0;"
          v-for="(item, index) in binddoclist"
          :label="item"
          :key="index"
          >{{ item.name }}</el-radio
        >
      </el-radio-group>
      <span slot="footer" class="dialog-footer">
        <el-button @click="exit()">取 消</el-button>
        <el-button type="primary" @click="subbinddoc">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
export default {
  props: ["assigdoctor", "assigdoctoritem"],
  data() {
    return {
      show: false,
      binddoccheckbox: "",
      binddoclist: [],
      sid: sessionStorage.getItem("id"),
    };
  },
  watch: {
    assigdoctor(o, n) {
      this.show = o;
      this.binddoccheckbox = "";
      this.binddoclist = [];
      if (o) {
        this.searchdoc(this.assigdoctoritem.Dis_ID);
      }
    },
  },
  methods: {
    exit() {
      this.show = false;
      this.$emit("update:assigdoctor", false);
    },
    searchdoc(disid) {
      this.axios.get("/field/get_docs_by_z_newseadoc_m_sid?s_id=" + disid).then((res) => {
        if (res.data.code == 0) {
          if (res.data.result.length > 0) {
            this.binddoclist = res.data.result;
          }
        } else {
          this.$message({
            type: "error",
            message: res.data.msg,
          });
          //   this.exit()
        }
      });
    },
    subbinddoc() {
      //   var form = {
      //     z_doc_id: this.binddoccheckbox.id,
      //     kid: this.assigdoctoritem.KID,
      //     uid: sessionStorage.getItem("servicer_id"),
      //   };
      var form1 = {
        doc_id: this.binddoccheckbox.id,
        kid: this.assigdoctoritem.KID,
        cust_id: this.assigdoctoritem.Cust_ID,
      };
      //   console.log(form);
      console.log(form1);
      // console.log(this.assigdoctoritem)
      // console.log(this.binddoccheckbox)
      this.$confirm("确定指定给" + this.binddoccheckbox.name + "医生吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.axios
            .post("/doc/update_z_newseadoc_main_docid", this.qs.stringify(form1))
            .then((res) => {
              this.$message({
                type: "success",
                message: "指定成功",
              });
              this.exit();
            })
            .catch((res) => {
              this.$message({
                type: "error",
                message: res.data.msg,
              });
            });
          //   this.axios
          //     .post("/field/update_z_newseadoc_main_zdocid", this.qs.stringify(form))
          //     .then((res) => {
          //       if (res.data.code == 0) {
          //       }
          //     });
          //   this.axios
          //     .post(
          //       "/field/insert_gu_ac_notice",
          //       this.qs.stringify({
          //         input_id: "s" + this.sid,
          //         content: "您下发的寻医表 "+this.assigdoctoritem.KID+" 已被 " + this.binddoccheckbox.name + " 医生抓取",
          //         type: "disman",
          //         op_id: this.assigdoctoritem.KID,
          //       })
          //     )
          //     .then((res) => {
          //       if (res.data.code == 0) {
          //         console.log(res);
          //       } else {
          //         console.log(res);
          //       }
          //     });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "取消操作",
          });
        });
    },
  },
};
</script>
